<template>
  <v-container id="preparations" fluid tag="section">
    <orders-view :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('preparation.list.empty.title')" :no-result-description="$t('preparation.list.empty.description')"
                 mode="list" :header="false" :style="'display:' + (orders.length > 0 ? 'block' : 'none')" @refresh="refresh"
    />
    <div v-if="$store.state.location !== undefined && ['orgeval', 'poissy'].includes($store.state.location.key) && pickupOrders.length > 0">
      <h3>{{ $t('preparation.pickup.title') }}</h3>
      <orders-view :orders="pickupOrders" :last-update="lastUpdate" :error="error" :no-result-title="$t('preparation.list.empty.title')" :no-result-description="$t('preparation.list.empty.description')"
                   mode="list" :header="false" @refresh="refresh"
      />
    </div>
    <production-tasks-view role="picker" />
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { secondToHHmm } from '@/util/DateUtil';
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import ProductionTasksView from '@/views/components/business/productionTask/ProductionTasksView';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'Preparation',
    components: { OrdersView, ProductionTasksView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: [],
        pickupOrders: []
      };
    },
    mounted () {
      this.setInfo(undefined);
    },
    destroyed () {
      this.setInfo(undefined);
    },
    methods: {
      maxTime () {
        return 22 * 60;
      },
      parseDriverAvailability (availability) {
        let color = 'green';
        if (availability.available > 1) {
          color = 'red';
        } else if (availability.available === 1) {
          color = 'orange';
        }
        return '<span style="color:' + color + ';">' + String(availability.available) + '/' + String(availability.total) + ' ' + this.$tc('order.performances.drivers', availability.available) + '</span>';
      },
      parsePreparationSpeed (speed) {
        return undefined;

        // if (speed.value === 0) {
        //   return undefined;
        // }
        //
        // const diff = (speed.avg === 0 ? 0 : ((speed.avg - speed.value) / Math.abs(speed.avg)) * 100);
        // let color = 'green';
        // let icon = 'speedometer';
        // if (diff < -10) { // %
        //   color = 'red';
        //   icon = 'speedometer-slow';
        // } else if (diff < 0) {
        //   color = 'orange';
        //   icon = 'speedometer-medium';
        // }
        // return '<span style="color:' + color + ';"><span class="mdi mdi-' + icon + '"></span> ' + secondToHHmm(speed.value) + '</span>'
        //   + ' / <span class="mdi mdi-medal-outline"></span> ' + secondToHHmm(speed.value > speed.avg ? speed.avg : speed.value * 0.75);
      },
      parseDeliveryTour (deliveryTour) {
        const tours = deliveryTour.isComplete ? 1 : 0;
        if (tours === 0) {
          return undefined;
        }

        const firstReadyAt = DateTime.fromISO(deliveryTour.firstReadyAt, { setZone: true }).setZone('UTC');
        const delay = secondToHHmm(firstReadyAt.isValid ? firstReadyAt.diffNow('seconds').seconds : 0);

        return '<span style="color:red;text-transform:uppercase;font-weight:bolder;">'
          + String(tours) + ' ' + this.$tc('order.performances.tours', tours) + ' (<span class="mdi mdi-timer-sand"></span>' + delay + ')</span>';
      },
      async refresh () {
        await this.refreshOrders();
        await this.refreshInfo();
      },
      async refreshInfo () {
        const info = [];
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/performances/preparation');
          if (response.data !== undefined && response.data.preparationSpeed !== undefined && response.data.driverAvailability !== undefined && response.data.deliveryTour !== undefined) {
            info.push(this.parsePreparationSpeed(response.data.preparationSpeed));
            // info.push(this.parseDriverAvailability(response.data.driverAvailability));
            info.push(this.parseDeliveryTour(response.data.deliveryTour));
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
        this.setInfo(info.filter((part) => part !== undefined).join(' | '));
      },
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/preparations/?maxTime=' + String(this.maxTime()));
          this.orders = response.data.preparation;
          this.pickupOrders = response.data.pickup;
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('preparation.error'));
        }
      },
      setInfo (info) {
        this.$store.commit('SET_PAGE_INFO_LINE2', {
          pageName: getPageName(),
          value: info
        });
      }
    }
  };
</script>
