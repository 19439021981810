<template>
  <div class="text-center">
    <v-card class="mx-auto pa-2" :color="getColor()" dark max-width="400">
      <v-card-title>
        <v-icon large dark left>
          mdi-{{ getIcon() }}
        </v-icon>
        <span class="text-h4 font-weight-bold">{{ task.name }}</span>
        <v-spacer />
        <v-icon v-if="task.priority === 'critical'" medium dark color="red" right>
          mdi-alert-decagram
        </v-icon>
      </v-card-title>
      <v-card-text class="text-h5 text-left font-weight-light white--text" v-html="descriptionToHtml" />
      <v-card-actions v-if="!readonly">
        <v-form ref="form" v-model="valid" lazy-validation style="width:100%">
          <v-row>
            <v-col v-if="Array.isArray(locationExecutors) && locationExecutors.length > 0" cols="6">
              <v-select v-model="executors" :items="locationExecutors" required multiple chips dense
                        :rules="executorsRules" :label="$t('productionTask.input.executors.title')" :clearable="true"
              />
            </v-col>
            <v-col :cols="Array.isArray(locationExecutors) && locationExecutors.length > 0 ? 6 : 12">
              <v-btn medium color="accent" class="ma-auto text-h5 font-weight-bold" :loading="loading" :disabled="loading || !valid" @click.stop="taskIsDone()">
                <v-icon medium dark class="mr-3">
                  mdi-check
                </v-icon>
                {{ $t('productionTask.button.done') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="task.skip">
            <v-col cols="12">
              <v-btn medium text color="white" class="text-none" :disabled="loading" :loading="loading" @click.stop="taskIsSkipped()">
                <v-icon medium dark color="white" left>
                  mdi-skip-next
                </v-icon>
                {{ task.skip }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import { marked } from 'marked';

  export default {
    name: 'ProductionTaskCard',
    props: {
      task: {
        type: Object,
        required: true
      },
      locationExecutors: {
        type: Array,
        required: true
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        loading: false,
        valid: true,
        icons: { date: 'calendar', cleaning: 'broom', cooking: 'chef-hat', placing: 'food-fork-drink', stock: 'wardrobe' },
        colors: { date: '#f1c40f', cleaning: '#26c6da', cooking: '#2ecc71', placing: '#e67e22', stock: '#9b59b6' },
        executors: [],
        executorsRules: [
          v => (Array.isArray(v) && v.length > 0) || this.$t('productionTask.input.executors.required')
        ]
      };
    },
    computed: {
      descriptionToHtml () {
        return marked(this.task.description);
      }
    },
    methods: {
      getColor () {
        if (this.colors[this.task.type]) {
          return this.colors[this.task.type];
        }

        return 'black';
      },
      getIcon () {
        if (this.icons[this.task.type]) {
          return this.icons[this.task.type];
        }

        return 'help-circle';
      },
      async taskIsDone () {
        this.valid = this.$refs.form.validate();

        if (this.valid) {
          this.loading = true;
          try {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'production/task/done', { taskId: this.task.id, executors: this.executors });
            this.$emit('success', this.task.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.loading = false;
        }
      },
      async taskIsSkipped () {
        this.loading = true;
        try {
          await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'production/task/skip', { taskId: this.task.id });
          this.$emit('success', this.task.id);
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
