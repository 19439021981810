<template>
  <v-container id="production-tasks-view" fluid tag="section">
    <alert-notification :message="alert" />
    <h1 v-if="!readonly" class="text-center">
      {{ $t('productionTask.subtitle') }}
    </h1>
    <v-row v-if="tasksTodo.length > 0">
      <v-col v-for="task in tasksTodo" :key="task.id" cols="12" sm="12" md="6" lg="4" xl="4">
        <production-task-card :task="task" :location-executors="executors" :readonly="readonly" @success="refreshTasks" @fail="fail" />
      </v-col>
    </v-row>
    <v-row v-else class="text-center">
      <v-col>
        {{ $t('productionTask.allTasksAreDone') }}
        <v-icon x-large dark color="green">
          mdi-party-popper
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { hasNewElement } from '@/util/ArrayUtil';
  import { ring } from '@/util/SoundUtil';
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import ProductionTaskCard from '@/views/components/business/productionTask/ProductionTaskCard';

  export default {
    name: 'ProductionTasksView',
    components: { AlertNotification, ProductionTaskCard },
    props: {
      tasks: {
        type: Array,
        required: false
      },
      role: {
        type: String,
        required: false
      }
    },
    data () {
      return {
        alert: '',
        loading: false,
        readonly: false,
        resfreshTimeout: null,
        tasksTodo: [],
        executors: []
      };
    },
    async mounted () {
      await this.refreshTasks();
      window.addEventListener('beforeunload', this.beforeUnload);
    },
    destroyed: async function () {
      this.cancelRefreshTimeout();
      if (this.tasksTodo.length === 0) {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    },
    methods: {
      beforeUnload (e) {
        const event = e || window.event;
        if (this.tasksTodo.length > 0 && ['preparation', 'mydelivery'].includes(getPageName())) {
          event.preventDefault();
          event.returnValue = this.$i18n.t('productionTask.someTasksAreUndone');

          confirm(this.$i18n.t('productionTask.someTasksAreUndone'));

          return this.$i18n.t('productionTask.someTasksAreUndone');
        }
      },
      cancelRefreshTimeout () {
        if (this.resfreshTimeout !== undefined) {
          clearInterval(this.resfreshTimeout);
        }
      },
      async refreshTasks () {
        if (Array.isArray(this.tasks)) {
          this.readonly = true;
          this.tasksTodo = this.tasks;
          return;
        }

        this.cancelRefreshTimeout();

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'production/task/todo' + (this.role === undefined ? '' : '?role=' + String(this.role)));
          if (typeof response.data === 'object' && typeof response.data.tasks === 'object') {
            if (hasNewElement(this.tasksTodo, response.data.tasks)) {
              ring(1.5);
            }
            this.tasksTodo = response.data.tasks;
            this.executors = response.data.executors;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('common.error.default'));
        }
        this.false = true;

        this.resfreshTimeout = setTimeout(async () => {
          await this.refreshTasks();
        }, 30000);
      },
      fail (error) {
        this.alert = error.message;
      }
    }
  };
</script>
